@import "src/styles/helpers";

.wrapper {
    display: flex;
    gap: 80px;

    @include media(desktopSmall) {
        gap: 96px;
    }

    @include media(desktop) {
        gap: 96px;
    }

    @include media(desktopBig) {
        gap: 96px;
    }

    @include media(desktopHuge) {
        gap: 96px;
    }
}

.leftColumn,
.rightColumn {
    width: 50%;
    flex: 1;
}

.point {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    padding: 24px 8px;
    border-bottom: 1px solid;
    @include setProperty(border-color, var(--dark-line-shape), var(--light-line-shape));
    overflow: hidden;
    width: 100%;

    .point__content {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .point__content__title {
            font-family: 'KharkivTone', sans-serif;
            @include setProperty(color, var(--secondary-50), var(--secondary-500));
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }

        .point__content__description {
            display: none;
            @include hideScrollbar;
            @include scrollOutOfContainer;
            @include setProperty(color, var(--secondary-50), var(--secondary-500));
            display: none;
            white-space: break-spaces;
            font-size: 14px;
            font-weight: 300;
            line-height: 28px;
        }
    }

    .point__icon {
        transform: rotate(-45deg);
        flex-shrink: 0;
        max-width: 24px;
        max-height: 24px;
        transition: transform .3s ease;
        @include setProperty(color, var(--secondary-50), var(--secondary-500));
    }

    &.active {
        .point__content {
            .point__content__title {
                @include setProperty(color, var(--primary-600), var(--primary-900));
            }

            .point__content__description {
                display: block;
            }

            .point__content__image {
                max-width: 472px;
                max-height: 160px;
            }
        }

        .point__icon {
            transform: rotate(0deg);
            @include setProperty(color, var(--primary-500), var(--primary-900));
        }
    }
}

.rightColumn {
    display: flex;
    flex-direction: column;

    h2 {
        @include setProperty(color, var(--secondary-50), var(--secondary-500));
        font-family: 'KharkivTone', sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
    }

    &__description {
        @include setProperty(color, var(--secondary-50), var(--secondary-500));
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
        margin-top: 16px;

        @include media(desktopSmall) {
            font-size: 16px;
            letter-spacing: 0.183px;
        }

        @include media(desktop) {
            font-size: 16px;
            letter-spacing: 0.183px;
        }

        @include media(desktopBig) {
            font-size: 16px;
            letter-spacing: 0.183px;
        }

        @include media(desktopHuge) {
            font-size: 16px;
            letter-spacing: 0.183px;
        }
    }

    &__image {
      justify-self: center;
      margin-top: 24px;
      max-width: 472px;
      max-height: 160px;

      @include setProperty(color, var(--secondary-50), var(--secondary-500));
        font-size: 14px;
        font-weight: 300;
        line-height: 28px;

        @include media(desktopSmall) {
            font-size: 16px;
            font-weight: 300;
            line-height: 32px;
            letter-spacing: 0.183px;
        }

        @include media(desktop) {
            font-size: 16px;
            font-weight: 300;
            line-height: 32px;
            letter-spacing: 0.183px;
        }

        @include media(desktopBig) {
            font-size: 16px;
            font-weight: 300;
            line-height: 32px;
            letter-spacing: 0.183px;
        }

        @include media(desktopHuge) {
            font-size: 16px;
            font-weight: 300;
            line-height: 32px;
            letter-spacing: 0.183px;
        }
    }
}