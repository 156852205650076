@import "src/styles/helpers";

.industriesSection {
  padding: 64px 0;
  display: flex;
  flex-direction: column;
  gap: 64px;

  @include media(portrait) {
      padding: 88px 0;
  }

  @include media(landscape) {
      padding: 88px 0;
  }

  @include media(desktopAll) {
      padding: 88px 0;
      gap: 96px;
  }

  &__title {
      @include setProperty(color, var(--secondary-50), var(--secondary-500));
      font-family: "KharkivTone", sans-serif;
      font-size: 28px;
      line-height: 36px;
      font-weight: 400;

      @include media(desktopAll) {
          font-size: 32px;
          font-weight: 400;
          line-height: 40px;
      }
  }

  &__list {
      display: flex;
      flex-wrap: wrap;
      gap: var(--margin-sm);

      @include media(mobile) {
          @include hideScrollbar;
          display: flex;
          flex-wrap: nowrap;
          gap: var(--margin-sm);
          overflow-x: auto;
          overflow-y: hidden;
          margin-right: calc(var(--container-padding-mobile) * -1);
          padding-right: var(--container-padding-mobile);
          margin-left: calc(var(--container-padding-mobile) * -1);
          padding-left: var(--container-padding-mobile);
      }

      @include media(portrait) {
          display: grid;
          grid-template-columns: 1fr 1fr;
      }

      @include media(landscape) {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
      }

      @include media(desktopAll) {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
      }
  }
}
