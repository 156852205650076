@import "src/styles/helpers";

.textReviewSection {
  padding-top: 64px;
  padding-bottom: 64px;

  @include media(portrait) {
      padding-top: 88px;
      padding-bottom: 88px;
  }

  @include media(landscape) {
      padding-top: 88px;
      padding-bottom: 88px;
  }

  @include media(desktopSmall) {
      padding-top: 88px;
      padding-bottom: 88px;
  }

  @include media(desktop) {
      padding-top: 88px;
      padding-bottom: 88px;
  }

  @include media(desktopBig) {
      padding-top: 88px;
      padding-bottom: 88px;
  }

  @include media(desktopHuge) {
      padding-top: 88px;
      padding-bottom: 88px;
  }
}

.textReviewCard {
  position: relative;
  overflow: hidden;
  padding: 24px;
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-radius: 16px;
  @include setProperty(border-color, var(--dark-line-low-accent), var(--light-line-low-accent));
  @include setProperty(background,
    linear-gradient(180deg, rgba(246, 255, 254, 0), rgba(246, 255, 254, 0), rgba(155, 188, 183, 0.05)),
    linear-gradient(180deg, rgba(81, 81, 81, 0), rgba(81, 81, 81, 0), rgba(60, 191, 166, 0.05)));

  @include media(portrait) {
    padding: 32px;
  }

  @include media(landscape) {
    padding: 48px;
  }

  @include media(desktopSmall) {
    padding: 64px;
  }

  @include media(desktop) {
    padding: 64px;
  }

  @include media(desktopBig) {
    padding: 64px;
  }

  @include media(desktopHuge) {
    padding: 64px;
  }

  .container {
    display: flex;
    flex-direction: column;

    @include media(landscape) {
      flex-direction: row;
      gap: 40px;
    }

    @include media(desktopAll) {
      flex-direction: row;
    }

    @include media(desktopSmall) {
      gap: 24px;
    }

    @include media(desktop) {
      gap: 24px;
    }

    @include media(desktopBig) {
      gap: 104px;
    }

    @include media(desktopHuge) {
      gap: 104px;
    }

    .avatar {
      display: flex;
      align-items: center;
      gap: 16px;
      flex-shrink: 0;

      @include media(landscape) {
        text-align: center;
        flex-direction: column;
        order: 1;
      }

      @include media(desktopAll) {
        text-align: center;
        flex-direction: column;
        order: 1;
      }

      .avatarImage {
        position: relative;
        height: 72px;
        width: 72px;

        @include media(landscape) {
          height: 84px;
          width: 84px;
        }

        @include media(desktopAll) {
          height: 84px;
          width: 84px;
        }

        img {
          border-radius: 50%;
        }
      }

      .nameAndPosition {
        width: 104px;
        display: flex;
        flex-direction: column;
        gap: 8px
      }

      .name {
        @include setProperty(color, var(--secondary-50), var(--secondary-500));
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }

      .position {
        @include setProperty(color, var(--secondary-50), var(--secondary-500));
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
        letter-spacing: 0.48px;
      }
    }

    .review {
      margin-top: 40px;
      @include setProperty(color, var(--secondary-50), var(--secondary-500));

      @include media(landscape) {
        margin-top: 0;
      }

      @include media(desktopAll) {
        margin-top: 0;
      }

      .title {
        @include setProperty(color, var(--secondary-50), var(--secondary-500));
        font-family: "KharkivTone", sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        text-transform: none;

        @include media(landscape) {
          font-size: 28px;
          line-height: 36px;
        }

        @include media(desktopAll) {
          font-size: 32px;
          font-weight: 400;
          line-height: 40px;
        }
      }

      .text {
        margin-top: 24px;
        @include setProperty(color, var(--secondary-50), var(--secondary-500));
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;

        @include media(desktopAll) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  .button {
    margin: 40px auto 0;

    @include media(tablet) {
      margin: 32px 0 0;
    }

    @include media(desktopAll) {
      margin: 32px 0 0;
    }

    svg {
      transform: rotate(-45deg);
    }
  }
}

.mapDecoration img {
  width: 60%;
  visibility: hidden;
  top: -1000;
  right: 0;
  position: absolute;
  @include setProperty(opacity, 0.1, 0.01);

  @include media(portrait) {
    visibility: visible;
    top: 0;
    right: -120px;
  }

  @include media(landscape) {
    visibility: visible;
    top: 0;
    right: 0px;
  }

  @include media(desktopSmall) {
    visibility: visible;
    top: 0;
    right: 0;
  }

  @include media(desktop) {
    visibility: visible;
    top: 0;
    right: 0;
  }

  @include media(desktopBig) {
    visibility: visible;
    top: 0;
    right: 0;
  }

  @include media(desktopHuge) {
    visibility: visible;
    top: 0;
    right: 0;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
