@import "src/styles/helpers";

.developmentSolutionsSection {
  padding-top: 64px;
  padding-bottom: 64px;

  @include media(portrait) {
      padding-top: 88px;
      padding-bottom: 88px;
  }

  @include media(landscape) {
      padding-top: 88px;
      padding-bottom: 88px;
  }

  @include media(desktopSmall) {
      padding-top: 88px;
      padding-bottom: 88px;
  }

  @include media(desktop) {
      padding-top: 88px;
      padding-bottom: 88px;
  }

  @include media(desktopBig) {
      padding-top: 88px;
      padding-bottom: 88px;
  }

  @include media(desktopHuge) {
      padding-top: 88px;
      padding-bottom: 88px;
  }

  &__title {
      @include setProperty(color, var(--secondary-50), var(--secondary-500));
      font-family: 'KharkivTone', sans-serif;
      font-size: 28px;
      font-weight: 400;
      line-height: 36px;
      margin-bottom: 64px;
      max-width: 425px;

      mark {
          @include setProperty(color, var(--primary-500), var(--primary-900));
      }

      @include media(desktopSmall) {
          font-size: 32px;
          font-weight: 400;
          line-height: 40px;
          margin-bottom: 96px;
          max-width: 445px;
      }

      @include media(desktop) {
          font-size: 32px;
          font-weight: 400;
          line-height: 40px;
          margin-bottom: 96px;
          max-width: 445px;
      }

      @include media(desktopBig) {
          font-size: 32px;
          font-weight: 400;
          line-height: 40px;
          margin-bottom: 96px;
          max-width: 445px;
      }

      @include media(desktopHuge) {
          font-size: 32px;
          font-weight: 400;
          line-height: 40px;
          margin-bottom: 96px;
          max-width: 445px;
      }
  }
}
