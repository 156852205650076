@import "src/styles/helpers";

.developmentServicesCard {
    padding-bottom: 32px;
    border-bottom: 1px solid;
    @include setProperty(border-color, var(--dark-line-shape), var(--light-line-shape));

    &__imageWrapper {
      padding: 12px;
      width: min-content;
      border-radius: 8px;
      @include setProperty(border, 1px solid var(--dark-line-shape), 1px solid var(--light-line-shape));
      @include setProperty(background, var(--secondary-800), var(--light-line-low-accent));

      &Icon {
        width: 36px;
        height: 36px;
      }
    }

    &__title {
        @include setProperty(color, var(--secondary-50), var(--secondary-500));
        font-family: 'KharkivTone', sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        margin-top: 28px;
        margin-bottom: 12px;

        @include media(portrait) {
            min-height: 64px;
        }

        @include media(landscape) {
            min-height: 64px;
        }
    }

    &__description {
        @include setProperty(color, var(--secondary-50), var(--secondary-500));
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;

        @include media(desktopSmall) {
            font-size: 16px;
            letter-spacing: 0.183px;
        }

        @include media(desktop) {
            font-size: 16px;
            letter-spacing: 0.183px;
        }

        @include media(desktopBig) {
            font-size: 16px;
            letter-spacing: 0.183px;
        }

        @include media(desktopHuge) {
            font-size: 16px;
            letter-spacing: 0.183px;
        }
    }
}