@import "src/styles/helpers";

.industry {
  position: relative;
  z-index: 1;
  flex: 1;
  padding: 24px;
  min-height: 96px;
  min-width: 245px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  border: 1px solid;
  @include setProperty(border-color, var(--dark-line-shape), var(--light-line-shape));
  border-radius: 16px;
  @include setProperty(background-color, var(--dark-bg), var(--light-bg));

  .imageWrapper {
    position: relative;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .image {
      object-fit: contain;
      position: absolute;
      height: 100%;
      width: auto;
    }
  }

  .title {
    font-size: 14px;
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    line-height: 20px;
    font-weight: 600;

    @include media(desktopAll) {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
