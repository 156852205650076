@import "src/styles/helpers";

.reasonsToOutsourceItem {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 32px;
    padding-bottom: 32px;

    &:not(:last-child) {
        border-bottom: 1px solid;
        @include setProperty(border-color, var(--dark-line-shape), var(--light-line-shape));
    }
    @include media(landscape) {
        padding-top: 48px;
        padding-bottom: 48px;
        flex-direction: row;
        gap: 64px;
    }

    @include media(desktopSmall) {
        padding-top: 48px;
        padding-bottom: 48px;
        flex-direction: row;
        gap: 64px;
    }

    @include media(desktop) {
        padding-top: 48px;
        padding-bottom: 48px;
        flex-direction: row;
        gap: 64px;
    }

    @include media(desktopBig) {
        padding-top: 48px;
        padding-bottom: 48px;
        flex-direction: row;
        gap: 64px;
    }

    @include media(desktopHuge) {
        padding-top: 48px;
        padding-bottom: 48px;
        flex-direction: row;
        gap: 64px;
    }

    &:first-child {
        padding-top: unset;
    }

    &__numberAndTitlePart {
        display: flex;
        flex-direction: column;
        gap: 8px;

        @include media(landscape) {
            flex-direction: row;
            gap: 24px;
        }

        @include media(desktopSmall) {
            flex-direction: row;
            gap: 24px;
        }

        @include media(desktop) {
            flex-direction: row;
            gap: 24px;
        }

        @include media(desktopBig) {
            flex-direction: row;
            gap: 24px;
        }

        @include media(desktopHuge) {
            flex-direction: row;
            gap: 24px;
        }


        &__number {
            @include setProperty(color, var(--secondary-100), var(--secondary-500));
            font-size: 14px;
            font-weight: 400;
            line-height: 28px;

            @include media(desktopSmall) {
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 0.183px;
            }

            @include media(desktop) {
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 0.183px;
            }

            @include media(desktopBig) {
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 0.183px;
            }

            @include media(desktopHuge) {
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 0.183px;
            }
        }

        &__title {
            @include setProperty(color, var(--secondary-50), var(--secondary-500));
            font-family: 'KharkivTone', sans-serif;
            font-size: 24px;
            font-weight: 400;
            line-height: 32px;

            @include media(landscape) {
                width: 328px;
            }

            @include media(desktopSmall) {
                width: 400px;
            }

            @include media(desktop) {
                width: 400px;
            }

            @include media(desktopBig) {
                width: 400px;
            }

            @include media(desktopHuge) {
                width: 400px;
            }
        }
    }

    &__description {
        @include setProperty(color, var(--secondary-50), var(--secondary-400));
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;

        @include media(landscape) {
            font-size: 16px;
        }

        @include media(desktopAll) {
            font-size: 16px;
        }
    }
}