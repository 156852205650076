@import "src/styles/helpers";

.developmentServicesSection {
  padding-top: 64px;
  padding-bottom: 64px;

  @include media(portrait) {
      padding-top: 88px;
      padding-bottom: 88px;
  }

  @include media(landscape) {
      padding-top: 88px;
      padding-bottom: 88px;
  }

  @include media(desktopSmall) {
      padding-top: 96px;
      padding-bottom: 88px;
  }

  @include media(desktop) {
      padding-top: 96px;
      padding-bottom: 88px;
  }

  @include media(desktopBig) {
      padding-top: 96px;
      padding-bottom: 88px;
  }

  @include media(desktopHuge) {
      padding-top: 96px;
      padding-bottom: 88px;
  }

  &__title {
      @include setProperty(color, var(--secondary-50), var(--secondary-500));
      font-family: 'KharkivTone', sans-serif;
      font-size: 28px;
      font-weight: 400;
      line-height: 36px;
      margin-bottom: 64px;
      max-width: 430px;

      mark {
          @include setProperty(color, var(--primary-500), var(--primary-900));
      }

      @include media(desktopSmall) {
          font-size: 32px;
          font-weight: 400;
          line-height: 40px;
          margin-bottom: 96px;
      }

      @include media(desktop) {
          font-size: 32px;
          font-weight: 400;
          line-height: 40px;
          margin-bottom: 96px;

      }

      @include media(desktopBig) {
          font-size: 32px;
          font-weight: 400;
          line-height: 40px;
          margin-bottom: 96px;
      }

      @include media(desktopHuge) {
          font-size: 32px;
          font-weight: 400;
          line-height: 40px;
          margin-bottom: 96px;
      }
  }

  &__cards {
      display: grid;
      flex-direction: column;
      grid-template-columns: 1fr;
      gap: 32px;

      @include media(portrait) {
          grid-template-columns: 1fr 1fr 1fr;
          gap: unset;
          column-gap: 32px;
          row-gap: 48px;
      }

      @include media(landscape) {
          grid-template-columns: 1fr 1fr 1fr;
          gap: unset;
          column-gap: 48px;
          row-gap: 64px;
      }

      @include media(desktopSmall) {
          grid-template-columns: 1fr 1fr 1fr;
          gap: unset;
          column-gap: 48px;
          row-gap: 64px;
      }

      @include media(desktop) {
          grid-template-columns: 1fr 1fr 1fr;
          gap: unset;
          column-gap: 48px;
          row-gap: 64px;
      }

      @include media(desktopBig) {
          grid-template-columns: 1fr 1fr 1fr;
          gap: unset;
          column-gap: 48px;
          row-gap: 64px;
      }

      @include media(desktopHuge) {
          grid-template-columns: 1fr 1fr 1fr;
          gap: unset;
          column-gap: 48px;
          row-gap: 64px;
      }
  }
}